import { api } from '@/api'

export const getRisks = async () => {
  const {
    data: { data },
    status,
  } = await api.get(
    `api/report/risks?filter=[["time_start","between",["2023-08-01","2024-09-01"]]]`
  )

  return { data, status }
}

export const getExportRisks = async params => {
  let requestUrl = 'api/report/risks/export'

  if (params.filters) {
    requestUrl += `?filter=${params.filters}`
  }

  const { data, status } = await api.get(requestUrl, {
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(data)

  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', 'Риски.xlsx')
  document.body.appendChild(link)

  link.click()

  return { data, status }
}
