import { defineStore } from 'pinia'
import axios from 'axios'
interface Language {
  code: string
  translations: Record<string, string>
  loaded: boolean
}

interface LanguageState {
  languages: Language[]
  currentLanguage: string
}

export const useLanguageStore = defineStore('language', {
  state: (): LanguageState => ({
    languages: [
      {
        code: 'ru',
        translations: {},
        loaded: false,
      },
      {
        code: 'en',
        translations: {},
        loaded: false,
      },
      // Добавить остальные языки
    ],
    currentLanguage: 'ru', // Русский язык по умолчанию
  }),
  actions: {
    getLanguage() {
      this.currentLanguage = localStorage.getItem('lang') || 'ru'
      this.setLanguage(this.currentLanguage)
    },
    async setLanguage(languageCode: string) {
      this.currentLanguage = languageCode
      localStorage.setItem('lang', this.currentLanguage)
      for (const lang of this.languages) {
        if (lang.code === languageCode && !lang.loaded) {
          await this.loadTranslations(lang)
        }
      }
    },
    async loadTranslations(language: Language) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_PATH}/storage/lang/${language.code}.json`
        )

        if (response.status === 200) {
          this.setTranslations(language, response.data)
          language.loaded = true
        }
      } catch (error) {
        console.error('Error loading translations:', error)
      }
    },
    setTranslations(language: Language, translations: Record<string, string>) {
      language.translations = translations
    },
    getTranslation(key: string): string {
      const selectedLanguage = this.languages.find(
        (lang: any) => lang.code === this.currentLanguage
      )
      if (selectedLanguage) {
        return selectedLanguage.translations[key]
      }
      return ''
    },
  },
})
