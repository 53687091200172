<template>
  <div class="legend">
    <div class="legend__item">
      <div :style="{ 'background-color': CIRCLE_MAIN_COLOR }" class="circle" />

      <span>загрузка</span>
    </div>

    <div class="legend__item">
      <div
        :style="{ 'background-color': CIRCLE_SECOND_COLOR }"
        class="circle"
      />

      <span>простой</span>
    </div>
  </div>
</template>

<script setup>
import { MAIN_COLOR, SECOND_COLOR } from '../config/consts'

const CIRCLE_MAIN_COLOR = MAIN_COLOR
const CIRCLE_SECOND_COLOR = SECOND_COLOR
</script>

<style scoped lang="scss">
.legend {
  display: flex;
  flex-direction: column;
  gap: 0px;

  &__item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  span {
    color: gray;
    font-weight: 500;
  }
}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
</style>
