import { api } from '@/api'

export const getCameras = async (filter?: '') => {
  const {
    data: { data },
    status,
  } = await api.get(`api/cameras?filter=${filter}`)

  return { data, status }
}
