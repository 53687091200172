import { api } from '@/api'

export const getDashboardData = async (id, startDate, endDate) => {
  const {
    data: { data },
    status,
  } = await api.get(
    `api/dashboard/locations?location_id=${id}&ds=${startDate}&de=${endDate}`
  )

  return { data, status }
}
