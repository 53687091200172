import axios from 'axios'
import Cookies from 'js-cookie'

const apiUrl = process.env.VUE_APP_API_PATH

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.defaults.transformRequest = function (data = {}) {
  const { isJson = true, ...body } = data

  if (!isJson) {
    const formData = new FormData()

    for (const key in body) {
      if (Array.isArray(body[key])) {
        for (const item of body[key]) {
          formData.append(key, item)
        }
      } else {
        formData.append(key, body[key])
      }
    }

    return formData
  } else {
    return JSON.stringify(data)
  }
}

const updateAuthorizationHeader = (token: string) => {
  if (token) {
    Cookies.set('user_token', token)
    api.defaults.headers['Authorization'] = `Bearer ${token}`
  } else {
    delete api.defaults.headers['Authorization']
  }
}

api.interceptors.request.use(
  config => {
    const token = Cookies.get('user_token')
    updateAuthorizationHeader(token)
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export { api, updateAuthorizationHeader }
