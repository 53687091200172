export const setLocalStorage = (key: string, data: unknown) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getLocalStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key))
}

export const setSessionStorage = (key: string, data: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(data))
}

export const getSessionStorage = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key))
}

export const deleteSessionStorage = (key: string) => {
  return sessionStorage.removeItem(key)
}
